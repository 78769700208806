// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { observer } from "mobx-react-lite";
import { ContentItemExtended } from "types/content";
import { useRouter } from "next/router";
import { useContentStore, useMemberJwt, useMember } from "lib/gf-app-context";
import { transformNavItems, shouldShow } from "lib/nav";
import EditableSection from "components/contentEditing/EditableSection";
import { NavItem } from "types/nav";
import { useEffect } from "react";
import Link from "next/link";
import { buildClassName } from "lib/string";
import clsx from "clsx";

interface Props {
  className?: string;
}

function SecondaryNavbar(props: Props) {
  const router = useRouter();
  const jwt = useMemberJwt();
  const loggedIn = !!jwt;
  const currentPath = router.asPath;
  const contentStore = useContentStore();
  const user = useMember();
  const roles = user?.account?.roles;

  if (!router) return null;

  const { className } = props;
  const secondaryNav = contentStore.navigation?.secondaryNav;
  const secondarySections = secondaryNav?.children;

  const currentSection =
    secondarySections &&
    secondarySections.find((section: ContentItemExtended) => {
      if (!shouldShow(section, loggedIn)) {
        return false;
      }

      const rootMatches =
        section.rootUri && currentPath.startsWith(section.rootUri);
      return (
        rootMatches || section.children.find((item) => item.uri === currentPath)
      );
    });

  const navItems =
    currentSection &&
    transformNavItems(
      currentSection.children as NavItem[],
      loggedIn,
      roles,
      currentPath
    );

  useEffect(() => {
    contentStore.loadNavigationData();
  }, [contentStore.navigation]);

  if (!(navItems && navItems.length)) {
    return null;
  }

  const mobileItems = navItems.filter(
    (item) => !item.settings || item.settings.isMobileVisible
  );

  return (
    <EditableSection
      navName="secondaryNav"
      className={buildClassName("SecondaryNavbar", className)}
    >
      <div className="ListWrapper flex flex-col md:hidden">
        <ul className="list-reset flex flex-row flex-wrap justify-center">
          {mobileItems.map((item: NavItem, key: number) => (
            <li className="pr-2 mr-3 mb-2 whitespace-nowrap" key={key}>
              <Link
                href={item.uri}
                className={`font-bold ${
                  item.active ? "text-spp-red" : "hover:text-spp-red"
                }`}>

                {item.label}

              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="ListWrapper flex flex-col hidden md:block">
        <ul className="list-reset flex flex-row justify-center ItemsParent">
          {navItems.map((item: NavItem, key: number) => (
            <li
              className={clsx("pr-2 mr-3", item.active && "active")}
              key={key}
            >
              <Link
                href={item.uri}
                className={clsx(
                  "font-bold",
                  item.active
                    ? "text-spp-red"
                    : "text-gray-darkest hover:text-spp-red"
                )}>

                {item.label}

              </Link>
            </li>
          ))}
        </ul>
      </div>
    </EditableSection>
  );
}

export default observer(SecondaryNavbar);
