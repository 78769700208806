// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import React from "react";
import Link from "next/link";
import Style from "lib/Style";
import clsx from "clsx";

interface Props {
  href: string;
  children: React.ReactNode;
  className?: string;
  download?: string;
  fontClass?: string;
  rel?: string;
  target?: string;
}

export default function LinkButton(props: Props) {
  const { children, className, download, fontClass, href, rel, target } = props;

  return (
    (<Link
      href={href}
      className={clsx(
        "LinkButton Button",
        Style.buttonShape,
        "bg-spp-violet hover:bg-spp-violet-lighter",
        fontClass || "font-semibold",
        "!text-white !hover:text-white hover:cursor-pointer hover:no-underline",
        className
      )}
      download={download}
      rel={rel}
      target={target}>

      {children}

    </Link>)
  );
}
