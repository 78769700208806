// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { useContext, useState } from "react";
import Link from "next/link";
import { NavItem } from "types/nav";
import { MpAppContext } from "lib/gf-app-context";
import { Me } from "types/member";
import MemberPhoto from "components/member/MemberPhoto";
import { observer } from "mobx-react-lite";
import { defaultUserPhotoUrl } from "lib/constants";
import { memberHasRoleAccess } from "lib/auth";

function Item(props: {
  link: NavItem;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}) {
  const { link, onClick } = props;
  const className = `${
    props.className || ""
  } block py-2 hover:bg-gray-lighter px-2`;
  return (
    (<Link href={link.uri} onClick={onClick} className={className}>

      {link.label}

    </Link>)
  );
}

interface Props {
  menuIsInline?: boolean;
}

function AccountDropdown({ menuIsInline }: Props) {
  const [menuOpen, setMenuOpen] = useState<boolean>(menuIsInline ?? false);
  const appContext = useContext(MpAppContext);
  const { userStore } = appContext;
  const user = userStore && userStore.user;

  function avatarRow(user: Me) {
    const { photo } = user.profile;
    const userPhotoUrl = photo?.url || defaultUserPhotoUrl;

    return (
      <div className="flex my-2 px-2 text-gray-darkest">
        <img
          src={userPhotoUrl}
          alt="Profile and account menu"
          className="w-10 h-10 rounded-full mr-3"
        />
        <div>
          <div>{user.account.name}</div>
          <div className="text-gray-dark">{user.profile.title}</div>
          <Link href="/members/profile" className="a" onClick={itemClicked}>
            
              edit profile
            
          </Link>
        </div>
      </div>
    );
  }

  function navItems() {
    const isAdmin =
      (user && memberHasRoleAccess(user.account, "admin")) || false;

    return (
      <>
        <Item
          link={{ uri: "/members/account", label: "Account" }}
          onClick={itemClicked}
        />
        {isAdmin && (
          <Item
            link={{ uri: "/_admin", label: "Admin" }}
            onClick={itemClicked}
          />
        )}
        <div className="border-b my-1" />
        <Item
          link={{ uri: "/logout", label: "Log Out" }}
          onClick={itemClicked}
        />
      </>
    );
  }

  function itemClicked() {
    if (!menuIsInline) {
      setMenuOpen(false);
    }
  }

  if (!user) {
    return null;
  }

  const absoluteClass = menuIsInline
    ? ""
    : "absolute z-10 shadow right-0 w-64 rounded-sm mt-2 pb-1";

  return (
    <div className="AccountDropdown relative text-sm">
      {!menuIsInline && (
        <button
          className="w-10 flex items-center hover:brightness-110 transition"
          style={{ position: "relative" }}
          onClick={() => !menuIsInline && setMenuOpen(!menuOpen)}
        >
          <MemberPhoto photoUrl={user.profile.photo?.url} photoSize={40} />
        </button>
      )}
      <div
        className={`${
          menuOpen ? "block" : "hidden"
        } ${absoluteClass} bg-white text-black border rounded-sm mt-2 pb-1`}
      >
        {avatarRow(user)}
        <div className="border-b my-1" />
        {navItems()}
      </div>
    </div>
  );
}

export default observer(AccountDropdown);
