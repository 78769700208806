var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f38e9337c735ce47bfd20919c2560e031f47fe75"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import nextConfigFn from "next/config";
const config = nextConfigFn();

const SENTRY_DSN =
  process.env.NODE_ENV === "development"
    ? ""
    : process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== "development") {
  const match = window.document.cookie
    .split("; ")
    .map((s) => s.split("="))
    .find((kv) => kv[0] === "gfsid");
  const gfSessionId = match && match[1];

  Sentry.init({
    environment: config.publicRuntimeConfig.deployEnv,
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    commit: config.publicRuntimeConfig.release,
    enabled: process.env.NODE_ENV !== "development",
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    initialScope: {
      gfSessionId,
    },
  });
}
